import React from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import "normalize.css";
import * as s from "./layout.module.scss";

export default function Layout({ title, children, className }) {
  return (
    <main className={clsx(s.main_container, className)}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {children}
    </main>
  );
}
